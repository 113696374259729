footer {
    padding: 70px 0 0;
    .container{
        padding-bottom: 70px;
    }
    h2 {
        margin-bottom: 100px;
        font-size: 42px;
        line-height: 47px;
        color: $black;
    }

    .icon {
        margin-bottom: 15px;
    }

    h3 {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: $text2;
    }

    .item {
        a {
            color: $text2;
        }
    }

    a:hover {
        color: $red;
    }
    
    .bg-footer{
        img{
            width: 100%;
        }
    }
}

header.overlay {
    .phone {
        a {
            color: $white;
            font-size: 20px;
        }
    }

    .item {
        margin-bottom: 15px;
    }

    address {
        font-size: 20px;
    }

    .btn {
        font-size: 20px;
    }
}

.modal {
    .title {
        font-size: 36px;
        line-height: 40px;
    }

    .modal-header {
        border-bottom: 0;
    }

    input {
        padding: 15px 10px 15px 25px;
        color: #000;
        border: 1px solid #616161;
        border-radius: 30px;
    }

    .btn {
        border-radius: 30px;
    }

    label {
        margin-bottom: 10px;
        font-size: 20px;
    }

    input {
        margin-bottom: 20px;
        display: block;
        width: 100%;
    }
}

.btn-call {
    position: fixed;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 50px;
    right: 50px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $red;
    cursor: pointer;
    transition: .3;
    animation: pulsing 2s infinite;
}

.active{
    background-color: $white;
    background-image: url("data:image/svg+xml,%3Csvg width='16' version='1.1' xmlns='http://www.w3.org/2000/svg' height='16' viewBox='0 0 64 64' xmlns:xlink='http://www.w3.org/1999/xlink' enable-background='new 0 0 64 64'%3E%3Cg%3E%3Cpath fill='%231D1D1B' d='M28.941,31.786L0.613,60.114c-0.787,0.787-0.787,2.062,0,2.849c0.393,0.394,0.909,0.59,1.424,0.59 c0.516,0,1.031-0.196,1.424-0.59l28.541-28.541l28.541,28.541c0.394,0.394,0.909,0.59,1.424,0.59c0.515,0,1.031-0.196,1.424-0.59 c0.787-0.787,0.787-2.062,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.062-0.786-2.848,0 L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    animation: none;
}

@keyframes pulsing {
  0% {
    transform: scale(0.9, 0.9)
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
}

.puls{
    display: none;
    position: fixed;
    bottom: 125px;
    right: 20px;
    background-color: $white;
    padding: 20px;
    max-width: 360px;
    transition: .3;
    .title{
        font-size: 32px;
        line-height: 37px;
    }
    
    input{
        padding: 5px 10px;
        color: #000;
        border: 1px solid #616161;
        min-height: 50px;
    }
    
    a{
        color: $text2;
    } 
}

.active2{
    display: block;
}