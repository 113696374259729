.first {
    padding: 200px 0;
    background-image: url(../img/main-bg.png);
    background-color: #c9e2e6;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;

    h1 {
        margin-bottom: 70px;
        font-size: 35px;
        line-height: 40px;
        color: $red;
        font-weight: 700;
    }

    h2 {
        max-width: 700px;
    }

    .btn {
        font-size: 22px;
    }
}

.calc {
    padding: 70px 0;

    h2 {
        font-size: 18px;
        color: $text2;
    }

    h3 {
        margin-bottom: 70px;
        color: $text2;
        font-size: 30px;
        max-width: 600px;
    }

    form {
        input {
            padding: 15px 10px 15px 25px;
            color: $black;
            border: 1px solid #616161;
            border-radius: 30px;
        }

        .btn {
            padding: 14px 40px;
            border-radius: 30px;
        }
    }
}

.plastik {
    padding: 70px 0 20px;

    h2 {
        font-size: 36px;
        line-height: 40px;
    }

    .item {
        cursor: pointer;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 15px;
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
        color: $red;
    }

    p {
        color: $text2;
    }
}



.know {

    .left {
        padding: 100px 0;
        border-radius: 0 3000px 3000px 0;
        background-color: #6eb2ff;
        height: 100%;
        text-align: right;

        img {
            padding-right: 100px;
            height: 70%;
            width: 70%;
            max-height: 330px;
        }
    }

    .text {
        padding: 70px 0;
        max-width: 530px;
    }

    h2 {
        font-weight: 36px;
        line-height: 40px;
        color: $text2;
    }

    p {
        margin-bottom: 70px;
        font-size: 20px;
        line-height: 24px;
        color: $text2;
    }

    .btn {
        border-radius: 30px;
    }
}

.all {
    padding: 70px 0;
    background-color: #f2f2f2;

    h2 {
        margin-bottom: 100px;
        font-size: 36px;
        line-height: 40px;
        color: $red;

    }

    h3 {
        margin-top: 20px;
        font-size: 20px;
        line-height: 24px;
        color: $red;
        font-weight: 700;
    }
}

.profile {
    padding: 70px 0;
    color: $text2;

    h2 {
        margin-bottom: 100px;
        font-size: 36px;
        line-height: 40px;
    }

    .col-lg-4 {
        margin-bottom: 30px;
    }

    .item {
        background-color: $white;
        padding: 20px 30px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .img-wrap {
        position: relative;

        &:hover {
            .img-hide {
                display: block;
            }
        }
    }

    .img-hide {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        transition: .3s;
    }

    h3 {
        margin: 25px 0 15px;
        color: $red;
        font-size: 30px;
        line-height: 35px;
        font-weight: 700;
        text-align: center;
    }


    ul {
        padding-left: 0;
    }

    li {

        font-size: 16px;

        span {
            font-weight: 700;
            font-size: 18px;
        }
    }

    h5 {
        font-size: 30px;
    }
}

.why {
    padding: 70px 0;
    color: $text2;
    background-color: #f2f2f2;

    h2 {
        margin-bottom: 100px;
        font-size: 36px;
        line-height: 40px;
    }

    .col-lg-3 {
        display: flex;
        align-items: center;

    }

    h3 {
        margin-left: 20px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
    }
}

.work {
    padding: 70px 0;
    color: $text2;

    h2 {
        margin-bottom: 100px;
        font-size: 36px;
        line-height: 40px;
    }

    .wrap {
        display: flex;

        img {
            width: 30px;
            height: 30px;
        }
    }

    h3 {

        margin-bottom: 10px;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;

    }
}

.size {
    padding-top: 80px;
    background-color: #e8edf3;

    .img-wrap {
        position: absolute;
        bottom: 0;
    }

    h2 {
        font-size: 30px;
        line-height: 34px;
    }

    p {
        font-size: 20px;
        line-height: 24px;
    }

    li {
        padding-left: 40px;
        font-size: 16px;
        line-height: 34px;
        background-image: url(../img/check.png);
        background-size: 30px;
        background-repeat: no-repeat;
    }

    .btn {
        border-radius: 30px;
    }
}

.info {
    padding: 70px 0 30px;

    h2 {
        margin-bottom: 70px;
        color: $red;
        font-size: 36px;
        line-height: 40px;
    }

    p {
        color: $text2;
        font-size: 16px;
        line-height: 28px;
    }
}

.slog {
    padding: 50px 0;
    background-color: #f2f2f2;
    font-size: 30px;
    line-height: 36px;
    color: $red;
}

@media(max-width: 1200px) {
    .work {

        h2 {
            margin-bottom: 70px;
        }

        .clock {
            margin-bottom: 0px;

            svg {
                margin-top: 0;
                margin-bottom: 25px;
            }
        }

        .wrap {
            margin-top: 70px;
        }

        .items {
            max-width: 400px;
            margin: auto;
        }

        .item {
            margin-bottom: 15px;
            position: relative;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            max-width: 100%;
            text-align: left;

            span {
                min-width: 40px;
            }
        }
    }
}

@media(max-width: 992px) {
    .profile {}

    .call {
        .col-lg-4 {
            margin-bottom: 15px;
        }

        input {
            min-height: 50px;
        }

        .btn {
            max-width: 300px;
            margin: auto;
        }
    }

    .size {
        margin-top: 50px;
        text-align: center;

        .img-wrap {
            position: relative;
        }
    }
}

@media(max-width: 740px) {
    .calc {
        form {
            flex-direction: column;

            input {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .price {
        form {
            flex-direction: column;

            input {
                width: 100%;
                margin: 0 0 10px 0 !important;
                min-height: 50px;

            }
        }

        .button-wrap {
            text-align: center;

            .btn {
                margin-right: 0 !important;
                margin-bottom: 15px;
            }
        }
    }

    .work {
        .wrap {
            flex-direction: column;

            p {
                text-align: center;
                margin: auto;
            }

            .block {
                margin-bottom: 20px;
            }
        }

    }
}

@media(max-width:576px) {
    .first {
        padding: 40px 0;

        h1 {
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 28px;
        }

        h2 {
            font-size: 25px;
        }

        .btn {
            font-size: 16px;
            padding: 8px 30px;
        }
    }

    .work .clock svg {
        width: 270px;
        height: 270px;
    }
}
